
import { Options, Vue } from 'vue-class-component'
import CustomHeader from '@/views/header.vue'
import CustomFooter from '@/views/footer.vue'

@Options({
  components: {
    'custom-header': CustomHeader,
    'custom-footer': CustomFooter
  }
})
export default class App extends Vue { }
