export default {
  en: {
    button: {
      gotIt: 'Got it!',
      restart: 'Restart',
      rules: 'Rules',
      start: 'start'
    }
  }
}
