<template>
  <div class="header row">

    <router-link id="name" class="col-sm-2 align-left m-3" to="/">
      <div>
        <h1>JOHNATHON</h1>
        <h1>WAIN</h1>
      </div>
    </router-link>

    <div class="row col" id="nav">
      <router-link class="col" to="/cards">CARDS</router-link>
      <router-link class="col" to="/sorting">SORTING VISUALISER</router-link>
      <router-link class="col" to="/farey">FAREY'S ALGORITHM</router-link>
    </div>
  </div>
</template>
