import sharedLocalisations from '@localizations/shared'
import dateTimeFormats from '@localizations/dateTimeFormats'

import { createI18n } from 'vue-i18n/index'

// Create VueI18n instance with options
const i18n = createI18n({
  locale: 'en', // set locale - only en for me:)
  messages: sharedLocalisations, // set locale messages
  dateTimeFormats, // set datetime formats
  silentFallbackWarn: true
})

export { i18n }
