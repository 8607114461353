import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  // --- Home ------
  {
    path: '/how-to',
    name: 'HowToHome',
    component: () => import('@/views/build-a-website.vue'),
    meta: { title: 'How to setup a website' },
    children: [
      { path: '', name: 'HowToOverview', redirect: { name: 'CreateRepo' } },
      { path: 'step-one', name: 'CreateRepo', component: () => import('@/views/how-to/create-repo.vue'), meta: { title: 'Creating a Devops repository' } },
      { path: 'step-two', name: 'CreateProject', component: () => import('@/views/how-to/create-project.vue'), meta: { title: 'Creating a Vue/TypeScript project' } },
      { path: 'step-three', name: 'HostOnAzure', component: () => import('@/views/how-to/host-on-azure.vue'), meta: { title: 'Host on Azure' } },
      { path: 'step-four', name: 'AutoRelease', component: () => import('@/views/how-to/auto-release.vue'), meta: { title: 'Auto-release pipeline on PR' } },
      { path: 'step-five', name: 'SyncWithGithub', component: () => import('@/views/how-to/sync-with-github.vue'), meta: { title: 'Sync with Github' } },
      { path: 'step-six', name: 'CustomDomain', component: () => import('@/views/how-to/custom-domain.vue'), meta: { title: 'Add a custom domain name' } }
    ]
  },
  // --- Games -----
  {
    path: '/cards',
    name: 'Cards',
    component: () => import('@/views/cards-menu.vue'),
    meta: { title: 'Cards' }
  },
  // --- About -----
  {
    path: '/',
    name: 'About',
    component: () => import('@/views/about-me.vue'),
    meta: { title: 'Johnathon Wain' }
  },
  // --- TODO ------
  {
    path: '/todo',
    name: 'TODO',
    component: () => import('@/views/todo-list.vue'),
    meta: { title: 'TODO' }
  },
  // --- Sources ---
  {
    path: '/sources',
    name: 'Sources',
    component: () => import('@/views/sources.vue'),
    meta: { title: 'Sources' }
  },
  // --- Face ---
  {
    path: '/face',
    name: 'Face',
    component: () => import('@/views/face.vue'),
    meta: { title: 'Face' }
  },
  // --- Sorting ---
  {
    path: '/sorting',
    name: 'Sorting',
    component: () => import('@/views/sorting-algorithms.vue'),
    meta: { title: 'Sorting' }
  },
  // --- Farey ---
  {
    path: '/farey',
    name: 'Farey',
    component: () => import('@/views/farey-algorithm.vue'),
    meta: { title: 'Farey\'s Algorithm' }
  },
  // --- Other ---
  {
    path: '/other',
    name: 'Other',
    component: () => import('@/views/other.vue'),
    meta: { title: 'Other' }
  },
  // --- Not Found ---
  {
    path: '/:pathMatch(.*)*',
    name: '404NotFound',
    component: () => import('@/views/not-found.vue'),
    meta: { title: 'Not Found' }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: routes
})

export default router
