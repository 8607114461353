<template>
  <div class="footer">

    <span id="name">
      JOHNATHON WAIN
    </span>

    <div class="align-center">
      <div class="btn-group" role=group>

        <a class="btn linked-in" target="_blank" href="https://www.linkedin.com/in/johnathon-wain-3253aa12b/">
          <fa-icon :icon="['fab', 'linkedin']" />
        </a>

        <a class="btn github" target="_blank" href="https://github.com/paintedrust">
          <fa-icon :icon="['fab', 'github']" />
        </a>

        <a class="btn email" target="_blank" href="mailto:johnathonwain18@gmail.com">
          <fa-icon icon="envelope" />
        </a>

        <a class="btn cv" href="johnathon-wain-cv.pdf" download>
          <fa-icon icon="file-contract" />
        </a>

        <router-link class="col other" to="/other">
          <fa-icon icon="question-circle" />
        </router-link>

      </div>
    </div>

  </div>
</template>
