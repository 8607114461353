import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faEnvelope, faChevronUp, faFileContract, faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons'

library.add(faLinkedin)
library.add(faFileContract)
library.add(faGithub)
library.add(faEnvelope)
library.add(faChevronUp)
library.add(faQuestionCircle)

export default FontAwesomeIcon
