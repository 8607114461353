import { createApp } from 'vue'
import App from './app.vue'
import router from './router'
import store from './store'

// Local scss
import '@css/site.scss'

// Equal UI
import Equal from 'equal-vue'
import 'equal-vue/dist/style.css'

// Font Awesome
import FontAwesomeIcon from '@utils/font-awesome-icon'

// Localizations
import { i18n } from './localizations/lang'

router.beforeEach((to, from, next) => {
  document.title = String(to.meta.title)
  next()
})

const app = createApp(App)
  .component('fa-icon', FontAwesomeIcon)
  .use(store)
  .use(router)
  .use(Equal)
  .use(i18n)

app.mount('#app')
